.get_started_button {
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
  z-index: 1000;

  .wrapper {
    position: relative;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border-radius: 2.5rem;
    background: var(--primary);
    border: 2px solid white;
    cursor: pointer;

    img {
      pointer-events: none;
    }
  }

  &:hover,
  &:active {
    .wrapper {
      .tooltip {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

.popover {
  width: 400px;
  height: 490px;
  border: 1px solid var(--grey-200);
  box-shadow: 0 0.25rem 2rem 0 rgba(0, 0, 0, 0.08);
  border-radius: 1rem;
  overflow: hidden;

  .book_meeting_btn {
    position: absolute;
    right: 4px;
    height: 40px;
    top: 4px;
    border-radius: 1rem !important;
  }
}

.popover_container {
  :global(.ant-popover-inner) {
    padding: 0;
    box-shadow: none;
    border-radius: 16px;
  }
}
