.ant-select-dropdown {
  border: 1px solid var(--grey-100);
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
  padding: 0.5rem;
  z-index: 1201 !important;
}

.ant-select-item.ant-select-item-option-disabled {
  opacity: 0.5;
}

.ant-select-dropdown .ant-select-item {
  color: var(--grey-500);
  padding: 0 0.75rem;
  min-height: 40px;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
}

.ant-select .ant-select-selector {
  box-shadow: none !important;
  background: transparent !important;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--primary);
  background-color: white;
}

.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: var(--grey-50);
}

.ant-select-single.ant-select-lg
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 40px;
  font-size: 0.875rem;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 40px;
}

.ant-select-selection-search-input::placeholder {
  color: red;
}

.ant-select .ant-select-selection-placeholder {
  color: var(--grey-300);
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  background: var(--accent-light-blue);
  padding-inline-end: 6px;
  color: var(--primary);
  border-radius: 8px;
  word-break: break-word;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item-remove {
  color: var(--primary);
}

.ant-select.ant-select-single .ant-select-selector .ant-select-selection-item {
  word-break: break-word;
  width: 1px;
}
