@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./toggle.css";
@import "./select.css";

:root {
  --primary: #364de7;
  --primary-50: #588dff;
  --primary-100: #5865f2;
  --primary-300: #688be3;
  --primary-800: #010f34;
  --primary-blue-100: #cdd8f6;
  --primary-blue-50: #e6ecfb;

  --accent: #ffb130;
  --red: #ed4245;
  --blue: #3e70dd;
  --light-blue: #d8e2f8;
  --orange: #fc8b23;

  --accent-yellow: #ffb130;
  --accent-yellow-50: #ffbc00;
  --accent-light-yellow: #ffefd6;
  --accent-orange: #fc8b23;
  --accent-light-orange: #fee4cd;
  --accent-green: #3ba55c;
  --accent-light-green: #d8edde;
  --accent-blue: #3e70dd;
  --accent-light-blue: #d8e2f8;
  --accent-violet: #702dff;
  --accent-violet-50: #a276ff;
  --accent-violet-100: #733fe7;
  --accent-light-violet: #dcccff;
  --accent-red: #ed4245;
  --accent-light-red: #fbd9da;
  --accent-silver: #747f8d;
  --accent-light-silver: #e3e5e8;
  --accent-pink: #ff73b6;

  --grey-50: #f2f3f5;
  --grey-100: #d6dae1;
  --grey-200: #c4c9d2;
  --grey-300: #a3a9b3;
  --grey-400: #7d828b;
  --grey-500: #5c6169;
  --grey-600: #36393f;
  --grey-700: #2c2e33;
  --grey-800: #202225;
  --grey-900: #17181a;
  --grey-950: #000915;
  --grey-1000: #dfdfdf;
  --grey-1050: #c8d8ff;

  --neutral-100: #f6f7f9;
  --neutral-200: #e3e7eb;
  --neutral-300: #f8f9fc;
  --neutral-400: #c3cad1;
  --neutral-500: #9da6af;
  --neutral-600: #596269;
  --neutral-700: #393e46;

  --background-color: #f8f9fc;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --gradient: linear-gradient(154deg, var(--grey-950) 0%, var(--primary) 100%);
  --gradient-100: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-200: linear-gradient(
    180deg,
    var(--neutral-200) 0%,
    var(--neutral-200) 100%
  );
  font-feature-settings: "salt" on, "ss01" on;
  /* font-family: "Inter var", "Inter", sans-serif; */
  font-family: system-ui, -apple-system, "Inter", sans-serif;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
  overflow-x: hidden;
  scroll-padding-top: 90px;
  background-color: white;
}

body {
  background-color: white;
}

.section-title {
  font-weight: 600;
  color: var(--grey-900);
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  z-index: 1;
  position: relative;
}

.section-title strong {
  font-weight: inherit;
  color: var(--primary);
}

@media (min-width: 1024px) {
  .section-title {
    font-size: 2rem;
    line-height: 1.25;
    margin-bottom: 3rem;
  }
}

#nprogress .spinner {
  display: none !important;
}

#nprogress .peg {
  box-shadow: none !important;
}

.text-primary {
  color: var(--primary);
}

.text-normal-blue {
  color: var(--blue);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.resources * {
  font-family: "Inter var", "Inter", sans-serif !important;
  text-align: left !important;
  color: var(--grey-700) !important;
}

.resources a,
.resources a * {
  color: var(--primary) !important;
}

.resources h1,
.resources h2,
.resources h3,
.resources h4,
.resources h5,
.resources h6,
.resources h1 *,
.resources h2 *,
.resources h3 *,
.resources h4 *,
.resources h5 *,
.resources h6 * {
  font-weight: 700 !important;
}

.resources h1,
.resources h1 * {
  font-size: 2rem !important;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.resources h2,
.resources h2 * {
  font-size: 1.5rem !important;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.resources h3,
.resources h3 * {
  font-size: 1.125rem !important;
  margin-bottom: 0.75rem;
  margin-top: 1rem;
}

.resources h4,
.resources h4 * {
  font-size: 1rem !important;
  margin-bottom: 0.75rem;
  margin-top: 1rem;
}

.resources p {
  margin: 0 0 1rem 0;
}

.resources table {
  border: 1px solid var(--grey-200);
}

.resources table thead tr th,
.resources table tbody td {
  padding: 0.5rem 0.75rem;
  border-right: 1px solid var(--grey-200);
  border-bottom: 1px solid var(--grey-200);
}

.resources ol li {
  list-style-type: auto;
  margin-bottom: 0.5rem;
}

.resources ul li {
  list-style-type: disc;
  margin-bottom: 0.5rem;
}

.error-box {
  padding: 8px;
  background-color: var(--accent-light-red);
  border-radius: 4px;
}
